@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto:wght@400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: var(--primary-text-color);
}

:root {
  --primary-text-color: #183b56;
  --secondary-text-color: #577592;
  --accent-color-dark: #1d69a3;
  --padding-inline-section: 20px;
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  color: var(--secondary-text-color);
  line-height: 1.8rem;
}

a {
  text-decoration: none;
  display: inline-block;
}

.small-blod-text {
  font-size: 1rem;
  font-weight: 700;
}

.container {
  max-width: 1180px;
  margin-inline: auto;
  padding-inline: var(--padding-inline-section);
}

.flex {
  display: flex;
  align-items: center;
}

.primary-button {
  background-color: var(--accent-color-dark);
  border-radius: 6px;
  font-weight: 500;
  color: white!important;
  padding: 12px 24px;
  box-shadow: 0 0 2px var(--secondary-text-color);
  transition: 0.2s ease-out;
  text-align: center;
}

.primary-button:hover {
  background-color: #252525;
  /* color: #252525; */
}

.secondary-button {
  border: 0.5px solid var(--secondary-text-color);
  border-radius: 6px;
  font-weight: 500;
  color: var(--primary-text-color);
  padding: 12px 24px;
  transition: 0.2s ease-out;
}

.secondary-button:hover {
  border-color:var(--accent-color-dark) ;
  color: var(--accent-color-dark) !important;
}

.hover-link {
  color: var(--primary-text-color);
}

.hover-link:hover {
  color: #1d69a3;
}

@media  (max-width:991px) {
  h3 {
    font-size: 1.2rem;
  }
}

@media (max-width:767px) {
  .primary-button  {
    width: 100%;
  }
}

@media (max-width:479px) {
  :root {
    --padding-inline-section: 10px;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
  
}