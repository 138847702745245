nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  }
  
  #navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
  }
  
  #navbar li a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #252525;
    transition: 1.9s all ease-in;
  }
  
  #navbar li a:hover {
    color: #001a49;
  }
  
  #navbar li a:hover::after {
    content: " ";
    width: 30%;
    height: 2px;
    background: #e9327c;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
  
  #mobile {
    display: none;
  }
  
  #mobile i {
    color: #000;
  }
  
  @media screen and (max-width: 769px) {
    #navbar {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: fixed;
      top: 90px;
      right: -300px;
      width: 300px;
      height: 100vh;
      /* background: #252525; */
      /* color: #fff; */
      box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
      padding: 40px 0 0 10px;
      transition: 0.3s ease-in-out;
    }
  
    #navbar.active {
      right: 0px;
    }
  
    #navbar li {
      margin-bottom: 25px;
    }
  
    #mobile {
      display: block;
    }
  
    #mobile i {
      font-size: 24px;
      cursor: pointer;
    }
  }
  