header {
    padding: 60px 20px 20px 0px;
}

.header-section {
    justify-content: center;
    gap:50px;
}

.header-left {
    max-width: 40vw;
}

.header-left h1 {
    margin-top: 20px;
}

.header-right img {
    width: 100%;
}

.get-started-btn{
    margin-top: 20px;
}

@media  (max-width:991px) {
   
}

@media (max-width:767px) {
    .header-section {
        flex-direction: column-reverse;
    }

    .header-left {
        max-width: 100vw;
        text-align: center;
    }
}